.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 0px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  width: max-content;
  left: -50px;
  bottom: 20px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 5px;
  right: 5px;
}

.ol-popup-closer:after {
  content: "✖";
  display: inline-block;
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 50%;
  border: 1px solid #bbb;
  text-align: center;
  line-height: 22px;
  color: #000;
  font-size: 10px;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  margin-left: 10px;
  border: 1px solid white;
}

.prosumer-name {
  display: inline-block;
  vertical-align: middle;
}

.text-left {
  text-align: left;
}
