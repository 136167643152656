.ol-popup {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: max-content;
  padding: 0;
  position: absolute;
  bottom: 20px;
  left: -50px;
  box-shadow: 0 1px 4px #0003;
}

.ol-popup:after, .ol-popup:before {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
}

.ol-popup:after {
  border-width: 10px;
  border-top-color: #fff;
  margin-left: -10px;
  left: 48px;
}

.ol-popup:before {
  border-width: 11px;
  border-top-color: #ccc;
  margin-left: -11px;
  left: 48px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 5px;
  right: 5px;
}

.ol-popup-closer:after {
  content: "✖";
  text-align: center;
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 10px;
  line-height: 22px;
  display: inline-block;
}

.dot {
  background-color: green;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.prosumer-name {
  vertical-align: middle;
  display: inline-block;
}

.text-left {
  text-align: left;
}

/*# sourceMappingURL=index.a0047d72.css.map */
